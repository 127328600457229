/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AdService } from './services/AdService';
import { AdsTxtService } from './services/AdsTxtService';
import { AiService } from './services/AiService';
import { AiPromptService } from './services/AiPromptService';
import { AuditLogService } from './services/AuditLogService';
import { BannerService } from './services/BannerService';
import { BinaryPollService } from './services/BinaryPollService';
import { BlockedDomainService } from './services/BlockedDomainService';
import { BlockedIpService } from './services/BlockedIpService';
import { BookmakerService } from './services/BookmakerService';
import { CommentService } from './services/CommentService';
import { CommentLikeService } from './services/CommentLikeService';
import { CompetitionService } from './services/CompetitionService';
import { ConfigService } from './services/ConfigService';
import { FeaturedCarouselService } from './services/FeaturedCarouselService';
import { FileService } from './services/FileService';
import { FlaggedCommentService } from './services/FlaggedCommentService';
import { ForbiddenWordService } from './services/ForbiddenWordService';
import { GrandPrixService } from './services/GrandPrixService';
import { HeadlinesDossierService } from './services/HeadlinesDossierService';
import { ImportService } from './services/ImportService';
import { KambiService } from './services/KambiService';
import { LocaleService } from './services/LocaleService';
import { LokaliseService } from './services/LokaliseService';
import { MatchService } from './services/MatchService';
import { MatchesService } from './services/MatchesService';
import { MatchOddsService } from './services/MatchOddsService';
import { MatchTickerService } from './services/MatchTickerService';
import { MobileConfigService } from './services/MobileConfigService';
import { ModuleScheduleService } from './services/ModuleScheduleService';
import { MonitoringService } from './services/MonitoringService';
import { NavItemService } from './services/NavItemService';
import { NewsService } from './services/NewsService';
import { NewsCategoryService } from './services/NewsCategoryService';
import { NewsMarketingBlockService } from './services/NewsMarketingBlockService';
import { NotificationService } from './services/NotificationService';
import { NotificationBarService } from './services/NotificationBarService';
import { OddsDossierService } from './services/OddsDossierService';
import { PageService } from './services/PageService';
import { PlatformControllerService } from './services/PlatformControllerService';
import { PlayerService } from './services/PlayerService';
import { PollService } from './services/PollService';
import { RedirectService } from './services/RedirectService';
import { RenameMeToFeaturedCarouselService } from './services/RenameMeToFeaturedCarouselService';
import { SecureService } from './services/SecureService';
import { SitemapService } from './services/SitemapService';
import { StatusService } from './services/StatusService';
import { SuperSecretService } from './services/SuperSecretService';
import { TagService } from './services/TagService';
import { TeamService } from './services/TeamService';
import { TestEventControllerService } from './services/TestEventControllerService';
import { TotoService } from './services/TotoService';
import { TournamentService } from './services/TournamentService';
import { UnibetService } from './services/UnibetService';
import { UserService } from './services/UserService';
import { VideofeedService } from './services/VideofeedService';
import { VideofeedConfigService } from './services/VideofeedConfigService';
import { WebpService } from './services/WebpService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class ApiClient {
    public readonly ad: AdService;
    public readonly adsTxt: AdsTxtService;
    public readonly ai: AiService;
    public readonly aiPrompt: AiPromptService;
    public readonly auditLog: AuditLogService;
    public readonly banner: BannerService;
    public readonly binaryPoll: BinaryPollService;
    public readonly blockedDomain: BlockedDomainService;
    public readonly blockedIp: BlockedIpService;
    public readonly bookmaker: BookmakerService;
    public readonly comment: CommentService;
    public readonly commentLike: CommentLikeService;
    public readonly competition: CompetitionService;
    public readonly config: ConfigService;
    public readonly featuredCarousel: FeaturedCarouselService;
    public readonly file: FileService;
    public readonly flaggedComment: FlaggedCommentService;
    public readonly forbiddenWord: ForbiddenWordService;
    public readonly grandPrix: GrandPrixService;
    public readonly headlinesDossier: HeadlinesDossierService;
    public readonly import: ImportService;
    public readonly kambi: KambiService;
    public readonly locale: LocaleService;
    public readonly lokalise: LokaliseService;
    public readonly match: MatchService;
    public readonly matches: MatchesService;
    public readonly matchOdds: MatchOddsService;
    public readonly matchTicker: MatchTickerService;
    public readonly mobileConfig: MobileConfigService;
    public readonly moduleSchedule: ModuleScheduleService;
    public readonly monitoring: MonitoringService;
    public readonly navItem: NavItemService;
    public readonly news: NewsService;
    public readonly newsCategory: NewsCategoryService;
    public readonly newsMarketingBlock: NewsMarketingBlockService;
    public readonly notification: NotificationService;
    public readonly notificationBar: NotificationBarService;
    public readonly oddsDossier: OddsDossierService;
    public readonly page: PageService;
    public readonly platformController: PlatformControllerService;
    public readonly player: PlayerService;
    public readonly poll: PollService;
    public readonly redirect: RedirectService;
    public readonly renameMeToFeaturedCarousel: RenameMeToFeaturedCarouselService;
    public readonly secure: SecureService;
    public readonly sitemap: SitemapService;
    public readonly status: StatusService;
    public readonly superSecret: SuperSecretService;
    public readonly tag: TagService;
    public readonly team: TeamService;
    public readonly testEventController: TestEventControllerService;
    public readonly toto: TotoService;
    public readonly tournament: TournamentService;
    public readonly unibet: UnibetService;
    public readonly user: UserService;
    public readonly videofeed: VideofeedService;
    public readonly videofeedConfig: VideofeedConfigService;
    public readonly webp: WebpService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.ad = new AdService(this.request);
        this.adsTxt = new AdsTxtService(this.request);
        this.ai = new AiService(this.request);
        this.aiPrompt = new AiPromptService(this.request);
        this.auditLog = new AuditLogService(this.request);
        this.banner = new BannerService(this.request);
        this.binaryPoll = new BinaryPollService(this.request);
        this.blockedDomain = new BlockedDomainService(this.request);
        this.blockedIp = new BlockedIpService(this.request);
        this.bookmaker = new BookmakerService(this.request);
        this.comment = new CommentService(this.request);
        this.commentLike = new CommentLikeService(this.request);
        this.competition = new CompetitionService(this.request);
        this.config = new ConfigService(this.request);
        this.featuredCarousel = new FeaturedCarouselService(this.request);
        this.file = new FileService(this.request);
        this.flaggedComment = new FlaggedCommentService(this.request);
        this.forbiddenWord = new ForbiddenWordService(this.request);
        this.grandPrix = new GrandPrixService(this.request);
        this.headlinesDossier = new HeadlinesDossierService(this.request);
        this.import = new ImportService(this.request);
        this.kambi = new KambiService(this.request);
        this.locale = new LocaleService(this.request);
        this.lokalise = new LokaliseService(this.request);
        this.match = new MatchService(this.request);
        this.matches = new MatchesService(this.request);
        this.matchOdds = new MatchOddsService(this.request);
        this.matchTicker = new MatchTickerService(this.request);
        this.mobileConfig = new MobileConfigService(this.request);
        this.moduleSchedule = new ModuleScheduleService(this.request);
        this.monitoring = new MonitoringService(this.request);
        this.navItem = new NavItemService(this.request);
        this.news = new NewsService(this.request);
        this.newsCategory = new NewsCategoryService(this.request);
        this.newsMarketingBlock = new NewsMarketingBlockService(this.request);
        this.notification = new NotificationService(this.request);
        this.notificationBar = new NotificationBarService(this.request);
        this.oddsDossier = new OddsDossierService(this.request);
        this.page = new PageService(this.request);
        this.platformController = new PlatformControllerService(this.request);
        this.player = new PlayerService(this.request);
        this.poll = new PollService(this.request);
        this.redirect = new RedirectService(this.request);
        this.renameMeToFeaturedCarousel = new RenameMeToFeaturedCarouselService(this.request);
        this.secure = new SecureService(this.request);
        this.sitemap = new SitemapService(this.request);
        this.status = new StatusService(this.request);
        this.superSecret = new SuperSecretService(this.request);
        this.tag = new TagService(this.request);
        this.team = new TeamService(this.request);
        this.testEventController = new TestEventControllerService(this.request);
        this.toto = new TotoService(this.request);
        this.tournament = new TournamentService(this.request);
        this.unibet = new UnibetService(this.request);
        this.user = new UserService(this.request);
        this.videofeed = new VideofeedService(this.request);
        this.videofeedConfig = new VideofeedConfigService(this.request);
        this.webp = new WebpService(this.request);
    }
}

