/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiClient } from './ApiClient';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Ad } from './models/Ad';
export type { AdActionPatch } from './models/AdActionPatch';
export { AdClient } from './models/AdClient';
export { AdPlacement } from './models/AdPlacement';
export type { AdsTxt } from './models/AdsTxt';
export type { AdsTxtActionUpdate } from './models/AdsTxtActionUpdate';
export { AdWrapperSize } from './models/AdWrapperSize';
export type { AIPrompt } from './models/AIPrompt';
export type { AiPromptActionUpdate } from './models/AiPromptActionUpdate';
export type { AiPromptBaseActionCreate } from './models/AiPromptBaseActionCreate';
export { AIPromptModel } from './models/AIPromptModel';
export { AIPromptType } from './models/AIPromptType';
export type { AIResult } from './models/AIResult';
export type { AJVError } from './models/AJVError';
export type { AlternateLink } from './models/AlternateLink';
export type { AuditLog } from './models/AuditLog';
export { AuditLogAction } from './models/AuditLogAction';
export { AuditLogEntity } from './models/AuditLogEntity';
export type { Author } from './models/Author';
export type { AuthorActionCreate } from './models/AuthorActionCreate';
export type { BadRequest } from './models/BadRequest';
export type { Banner } from './models/Banner';
export type { BannerActionCreate } from './models/BannerActionCreate';
export type { BannerActionUpdate } from './models/BannerActionUpdate';
export type { BinaryPoll } from './models/BinaryPoll';
export { BinaryPollVote } from './models/BinaryPollVote';
export type { BlockedDomain } from './models/BlockedDomain';
export type { BlockedDomainActionCreate } from './models/BlockedDomainActionCreate';
export type { BlockedDomainUser } from './models/BlockedDomainUser';
export type { BlockedIp } from './models/BlockedIp';
export type { BlockedIpUser } from './models/BlockedIpUser';
export type { Bookmaker } from './models/Bookmaker';
export type { BookmakerActionCreate } from './models/BookmakerActionCreate';
export type { BookmakerActionPatch } from './models/BookmakerActionPatch';
export type { BookmakerBase } from './models/BookmakerBase';
export { CardSeverity } from './models/CardSeverity';
export type { Comment } from './models/Comment';
export type { CommentActionCreate } from './models/CommentActionCreate';
export type { CommentActionPatch } from './models/CommentActionPatch';
export type { CommentFull } from './models/CommentFull';
export { CommentOrderByOption } from './models/CommentOrderByOption';
export type { CommentScopePublic } from './models/CommentScopePublic';
export { CommentStatusFilter } from './models/CommentStatusFilter';
export type { CommentUpdateList } from './models/CommentUpdateList';
export type { CommentUpdateListResponse } from './models/CommentUpdateListResponse';
export type { CommentUser } from './models/CommentUser';
export { CompetitionType } from './models/CompetitionType';
export type { Config } from './models/Config';
export { ConfigKey } from './models/ConfigKey';
export type { EmailOrUsernameExists } from './models/EmailOrUsernameExists';
export type { EmailVerify } from './models/EmailVerify';
export { EventType } from './models/EventType';
export { ExperimentGroup } from './models/ExperimentGroup';
export { ExperimentGroupOrRobots } from './models/ExperimentGroupOrRobots';
export type { FeaturedCarousel } from './models/FeaturedCarousel';
export type { FeaturedCarouselActionUpsert } from './models/FeaturedCarouselActionUpsert';
export { FeaturedCarouselFontSize } from './models/FeaturedCarouselFontSize';
export { FeaturedCarouselLayout } from './models/FeaturedCarouselLayout';
export type { FeaturedItem } from './models/FeaturedItem';
export type { FeaturedItemUpdatePartial } from './models/FeaturedItemUpdatePartial';
export type { FlaggedComment } from './models/FlaggedComment';
export type { ForbiddenWord } from './models/ForbiddenWord';
export type { ForbiddenWordActionCreate } from './models/ForbiddenWordActionCreate';
export type { ForbiddenWordActionPatch } from './models/ForbiddenWordActionPatch';
export type { ForceUpdateConfig } from './models/ForceUpdateConfig';
export type { FullContext } from './models/FullContext';
export type { GenericError } from './models/GenericError';
export { GoalType } from './models/GoalType';
export type { GrandPrix } from './models/GrandPrix';
export type { GrandPrixEvent } from './models/GrandPrixEvent';
export type { GrandPrixSchedule } from './models/GrandPrixSchedule';
export type { HeadlinesDossier } from './models/HeadlinesDossier';
export type { Image } from './models/Image';
export { ImageSize } from './models/ImageSize';
export type { KambiSyncResult } from './models/KambiSyncResult';
export type { Locale } from './models/Locale';
export { LocaleID } from './models/LocaleID';
export type { LokaliseKey } from './models/LokaliseKey';
export type { Match } from './models/Match';
export type { MatchOdds } from './models/MatchOdds';
export { MatchPeriod } from './models/MatchPeriod';
export { MatchPhase } from './models/MatchPhase';
export type { MatchReport } from './models/MatchReport';
export { MatchStatus } from './models/MatchStatus';
export type { MatchTickerItem } from './models/MatchTickerItem';
export type { MatchTickerItemActionCreate } from './models/MatchTickerItemActionCreate';
export type { MatchTickerItemActionPatch } from './models/MatchTickerItemActionPatch';
export { MatchTickerOrderByOption } from './models/MatchTickerOrderByOption';
export type { MatchTime } from './models/MatchTime';
export type { MobileConfig } from './models/MobileConfig';
export { MobileConfigClient } from './models/MobileConfigClient';
export type { ModuleScheduleItem } from './models/ModuleScheduleItem';
export type { ModuleScheduleItemActionCreateActionCreate } from './models/ModuleScheduleItemActionCreateActionCreate';
export type { ModuleScheduleItemActionPatch } from './models/ModuleScheduleItemActionPatch';
export { ModuleScheduleOrderByOptions } from './models/ModuleScheduleOrderByOptions';
export { ModuleScheduleSection } from './models/ModuleScheduleSection';
export { ModuleScheduleStatus } from './models/ModuleScheduleStatus';
export type { MyInfo } from './models/MyInfo';
export type { NavItem } from './models/NavItem';
export type { NavItemActionCreate } from './models/NavItemActionCreate';
export type { NavItemActionPatch } from './models/NavItemActionPatch';
export type { NestedItem } from './models/NestedItem';
export type { News } from './models/News';
export type { NewsActionCreate } from './models/NewsActionCreate';
export type { NewsAlternateLink } from './models/NewsAlternateLink';
export type { NewsCategory } from './models/NewsCategory';
export type { NewsCategoryActionPatch } from './models/NewsCategoryActionPatch';
export type { NewsExperiment } from './models/NewsExperiment';
export type { NewsExperimentActionCreate } from './models/NewsExperimentActionCreate';
export type { NewsMarketingBlock } from './models/NewsMarketingBlock';
export type { NewsReadMoreArticle } from './models/NewsReadMoreArticle';
export type { NewsReadMoreArticleActionCreate } from './models/NewsReadMoreArticleActionCreate';
export type { NewsReadMoreBlock } from './models/NewsReadMoreBlock';
export type { NewsReadMoreBlockActionCreate } from './models/NewsReadMoreBlockActionCreate';
export { NewsStatus } from './models/NewsStatus';
export type { NewsThumb } from './models/NewsThumb';
export type { NewsVideofeed } from './models/NewsVideofeed';
export type { NewsVideofeedActionCreate } from './models/NewsVideofeedActionCreate';
export type { NotFound } from './models/NotFound';
export type { Notification } from './models/Notification';
export type { NotificationBar } from './models/NotificationBar';
export type { NotificationBarActionCreateActionCreate } from './models/NotificationBarActionCreateActionCreate';
export type { NotificationBarActionPatch } from './models/NotificationBarActionPatch';
export { NotificationBarCategory } from './models/NotificationBarCategory';
export { NotificationBarOrderByOption } from './models/NotificationBarOrderByOption';
export type { NotificationCountContainer } from './models/NotificationCountContainer';
export { NotificationType } from './models/NotificationType';
export type { NotificationUpdateList } from './models/NotificationUpdateList';
export type { NotificationUpdateListResponse } from './models/NotificationUpdateListResponse';
export type { OddsDossier } from './models/OddsDossier';
export type { OddsDossierActionUpsert } from './models/OddsDossierActionUpsert';
export type { OddsDossierItem } from './models/OddsDossierItem';
export type { OddsDossierItemActionUpsert } from './models/OddsDossierItemActionUpsert';
export type { OddsDossierMatch } from './models/OddsDossierMatch';
export type { OddsDossierMatchActionUpsert } from './models/OddsDossierMatchActionUpsert';
export { OddsProvider } from './models/OddsProvider';
export { Order } from './models/Order';
export type { Page } from './models/Page';
export type { Pagination } from './models/Pagination';
export type { PartialTag } from './models/PartialTag';
export type { PasswordReset } from './models/PasswordReset';
export { PeriodEventType } from './models/PeriodEventType';
export type { PlacementList } from './models/PlacementList';
export type { Platform } from './models/Platform';
export { PlatformID } from './models/PlatformID';
export { PlayerPosition } from './models/PlayerPosition';
export { PlayerRole } from './models/PlayerRole';
export type { Poll } from './models/Poll';
export type { PollActionCreate } from './models/PollActionCreate';
export type { PollActionPatch } from './models/PollActionPatch';
export type { PollActionPatchAnswer } from './models/PollActionPatchAnswer';
export type { PollAnswer } from './models/PollAnswer';
export type { PollAnswerActionCreate } from './models/PollAnswerActionCreate';
export type { ProfileActionPatch } from './models/ProfileActionPatch';
export type { Redirect } from './models/Redirect';
export type { RedirectActionCreate } from './models/RedirectActionCreate';
export type { RedirectActionUpdate } from './models/RedirectActionUpdate';
export { Scope } from './models/Scope';
export type { SignInPassword } from './models/SignInPassword';
export type { SignUpPassword } from './models/SignUpPassword';
export type { SitemapEntry } from './models/SitemapEntry';
export type { SitemapGoogleEntry } from './models/SitemapGoogleEntry';
export type { SitemapIndexEntry } from './models/SitemapIndexEntry';
export type { SportDataCard } from './models/SportDataCard';
export type { SportDataCompetition } from './models/SportDataCompetition';
export type { SportDataGoal } from './models/SportDataGoal';
export type { SportDataMatch } from './models/SportDataMatch';
export type { SportDataMatchDetails } from './models/SportDataMatchDetails';
export type { SportDataMatchStatistic } from './models/SportDataMatchStatistic';
export type { SportDataPenaltyMissed } from './models/SportDataPenaltyMissed';
export type { SportDataPeriod } from './models/SportDataPeriod';
export type { SportDataPlayer } from './models/SportDataPlayer';
export type { SportDataPlayerClubTeam } from './models/SportDataPlayerClubTeam';
export type { SportDataPlayerMembership } from './models/SportDataPlayerMembership';
export type { SportDataPlayerPassport } from './models/SportDataPlayerPassport';
export type { SportDataPlayerStats } from './models/SportDataPlayerStats';
export type { SportDataShootoutPenalty } from './models/SportDataShootoutPenalty';
export type { SportDataSubstitution } from './models/SportDataSubstitution';
export type { SportDataTeam } from './models/SportDataTeam';
export type { SportDataTeamSelection } from './models/SportDataTeamSelection';
export type { SportDataTeamStanding } from './models/SportDataTeamStanding';
export type { SportDataTeamStatistics } from './models/SportDataTeamStatistics';
export type { SportDataTournament } from './models/SportDataTournament';
export type { SportDataTournamentRanking } from './models/SportDataTournamentRanking';
export type { SportDataTournamentRankingPosition } from './models/SportDataTournamentRankingPosition';
export type { SportDataTournamentRankingPositionPlayer } from './models/SportDataTournamentRankingPositionPlayer';
export type { SportDataTournamentStage } from './models/SportDataTournamentStage';
export type { SportDataTournamentStandings } from './models/SportDataTournamentStandings';
export { StatType } from './models/StatType';
export { SubjectID } from './models/SubjectID';
export { SupertokensAuthMode } from './models/SupertokensAuthMode';
export type { Tag } from './models/Tag';
export type { TagType } from './models/TagType';
export { TagTypeAsString } from './models/TagTypeAsString';
export type { TestEventRequest } from './models/TestEventRequest';
export { TestEventType } from './models/TestEventType';
export type { ThreeWayOdds } from './models/ThreeWayOdds';
export type { TmpFile } from './models/TmpFile';
export type { TotoOddsImportResult } from './models/TotoOddsImportResult';
export type { TotoSyncResult } from './models/TotoSyncResult';
export type { Unauthorized } from './models/Unauthorized';
export type { UnibetOddsImportResult } from './models/UnibetOddsImportResult';
export type { User } from './models/User';
export type { UserActionPatch } from './models/UserActionPatch';
export { UserRole } from './models/UserRole';
export type { UserSessionDeleteResult } from './models/UserSessionDeleteResult';
export type { ValidationError } from './models/ValidationError';
export type { ValidationTest } from './models/ValidationTest';
export type { VideofeedConfig } from './models/VideofeedConfig';
export { VideofeedConfigClient } from './models/VideofeedConfigClient';
export type { VideofeedItem } from './models/VideofeedItem';
export type { VideofeedItemActionCreate } from './models/VideofeedItemActionCreate';
export type { VideofeedItemActionCreateResponseScopeAdmin } from './models/VideofeedItemActionCreateResponseScopeAdmin';
export type { VideofeedItemActionPatch } from './models/VideofeedItemActionPatch';
export type { VideofeedItemActionUpdateResponseScopeAdmin } from './models/VideofeedItemActionUpdateResponseScopeAdmin';
export type { VideofeedItemPublicScope } from './models/VideofeedItemPublicScope';
export { VideofeedOrderBy } from './models/VideofeedOrderBy';
export { VideofeedStatus } from './models/VideofeedStatus';
export { VideofeedStatusPatch } from './models/VideofeedStatusPatch';
export { VideofeedVendor } from './models/VideofeedVendor';
export type { VideofeedVendorConfig } from './models/VideofeedVendorConfig';
export type { WarmUpCache } from './models/WarmUpCache';
export { WarmUpCacheType } from './models/WarmUpCacheType';

export { AdService } from './services/AdService';
export { AdsTxtService } from './services/AdsTxtService';
export { AiService } from './services/AiService';
export { AiPromptService } from './services/AiPromptService';
export { AuditLogService } from './services/AuditLogService';
export { BannerService } from './services/BannerService';
export { BinaryPollService } from './services/BinaryPollService';
export { BlockedDomainService } from './services/BlockedDomainService';
export { BlockedIpService } from './services/BlockedIpService';
export { BookmakerService } from './services/BookmakerService';
export { CommentService } from './services/CommentService';
export { CommentLikeService } from './services/CommentLikeService';
export { CompetitionService } from './services/CompetitionService';
export { ConfigService } from './services/ConfigService';
export { FeaturedCarouselService } from './services/FeaturedCarouselService';
export { FileService } from './services/FileService';
export { FlaggedCommentService } from './services/FlaggedCommentService';
export { ForbiddenWordService } from './services/ForbiddenWordService';
export { GrandPrixService } from './services/GrandPrixService';
export { HeadlinesDossierService } from './services/HeadlinesDossierService';
export { ImportService } from './services/ImportService';
export { KambiService } from './services/KambiService';
export { LocaleService } from './services/LocaleService';
export { LokaliseService } from './services/LokaliseService';
export { MatchService } from './services/MatchService';
export { MatchesService } from './services/MatchesService';
export { MatchOddsService } from './services/MatchOddsService';
export { MatchTickerService } from './services/MatchTickerService';
export { MobileConfigService } from './services/MobileConfigService';
export { ModuleScheduleService } from './services/ModuleScheduleService';
export { MonitoringService } from './services/MonitoringService';
export { NavItemService } from './services/NavItemService';
export { NewsService } from './services/NewsService';
export { NewsCategoryService } from './services/NewsCategoryService';
export { NewsMarketingBlockService } from './services/NewsMarketingBlockService';
export { NotificationService } from './services/NotificationService';
export { NotificationBarService } from './services/NotificationBarService';
export { OddsDossierService } from './services/OddsDossierService';
export { PageService } from './services/PageService';
export { PlatformControllerService } from './services/PlatformControllerService';
export { PlayerService } from './services/PlayerService';
export { PollService } from './services/PollService';
export { RedirectService } from './services/RedirectService';
export { RenameMeToFeaturedCarouselService } from './services/RenameMeToFeaturedCarouselService';
export { SecureService } from './services/SecureService';
export { SitemapService } from './services/SitemapService';
export { StatusService } from './services/StatusService';
export { SuperSecretService } from './services/SuperSecretService';
export { TagService } from './services/TagService';
export { TeamService } from './services/TeamService';
export { TestEventControllerService } from './services/TestEventControllerService';
export { TotoService } from './services/TotoService';
export { TournamentService } from './services/TournamentService';
export { UnibetService } from './services/UnibetService';
export { UserService } from './services/UserService';
export { VideofeedService } from './services/VideofeedService';
export { VideofeedConfigService } from './services/VideofeedConfigService';
export { WebpService } from './services/WebpService';
