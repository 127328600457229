import React, { FC, PropsWithChildren } from 'react';

import { Platform } from '@common/clients/api';

import { ErrorBackground } from '../../molecules';
import { ErrorTag } from '../../molecules/ErrorTag/ErrorTag';

import styles from './ErrorIntroBlock.module.scss';

interface Props extends PropsWithChildren {
    platform?: Platform;
    tagText?: string;
}

export const ErrorIntroBlock: FC<Props> = ({ children, tagText, platform }) => {
    return (
        <section className={[styles.ErrorIntroBlock, 'inverted'].join(' ')}>
            <ErrorBackground platform={platform} />
            <ErrorTag tagText={tagText} platform={platform} />
            {children}
        </section>
    );
};
