import { ElementType, FC, PropsWithChildren } from 'react';

import styles from './Badge.module.scss';

export type BadgeBg =
    | 'main'
    | 'second'
    | 'third'
    | 'fourth'
    | 'text'
    | 'lighter'
    | 'success'
    | 'warning'
    | 'error';

export type BadgeSize = 'tiny' | 'small' | 'standard' | 'large' | 'huge';

export type BadgePosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface BadgeProps extends PropsWithChildren {
    bg: BadgeBg;
    size?: BadgeSize;
    position?: BadgePosition;
    rounded?: boolean;
    text?: string | number;
    as?: ElementType;
}

export const Badge: FC<BadgeProps> = ({
    bg = 'main',
    size = 'small',
    rounded = false,
    position,
    text,
    as: Element = 'span',
    children,
    ...props
}) => {
    const classes = [styles.Badge, styles[`bg-${bg}`], styles[`size-${size}`]];
    if (rounded) classes.push(styles[`rounded`]);
    if (position) classes.push(styles[`position-${position}`]);

    return (
        <Element className={classes.join(' ')} {...props}>
            {text || children}
        </Element>
    );
};
