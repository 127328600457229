/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocaleID } from '../models/LocaleID';
import type { ModuleScheduleItem } from '../models/ModuleScheduleItem';
import type { ModuleScheduleItemActionCreateActionCreate } from '../models/ModuleScheduleItemActionCreateActionCreate';
import type { ModuleScheduleItemActionPatch } from '../models/ModuleScheduleItemActionPatch';
import type { ModuleScheduleOrderByOptions } from '../models/ModuleScheduleOrderByOptions';
import type { ModuleScheduleSection } from '../models/ModuleScheduleSection';
import type { ModuleScheduleStatus } from '../models/ModuleScheduleStatus';
import type { Order } from '../models/Order';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';
import type { Scope } from '../models/Scope';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ModuleScheduleService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get module schedule items
     * Return a list of module schedule items for a given domainID or platform and locale
     * @returns any Success
     * @throws ApiError
     */
    public listModuleScheduleByDomainId({
        contextId,
        section,
        tagId,
        orderBy,
        order,
        scope,
        status,
        page = 1,
        perPage = 50,
        cache,
    }: {
        /**
         * will return items with contextID null and the current contextID
         */
        contextId: number,
        /**
         * filter specific on the module schedule items for a given module
         */
        section?: ModuleScheduleSection,
        /**
         * filter on module schedule items with a given tagID (module schedule items's with NULL as tagID will also be returned), if null for scope public we only return items with tagID === null
         */
        tagId?: number,
        /**
         * will return items ordered by a given column
         */
        orderBy?: ModuleScheduleOrderByOptions,
        /**
         * order results in either ascending or descending order
         */
        order?: Order,
        scope?: Scope,
        /**
         * Filter items by ModuleScheduleStatus
         */
        status?: Array<ModuleScheduleStatus>,
        page?: number,
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<ModuleScheduleItem>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/module-schedule',
            path: {
                'contextID': contextId,
            },
            query: {
                'section': section,
                'tagID': tagId,
                'orderBy': orderBy,
                'order': order,
                'scope': scope,
                'status': status,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create a module schedule item
     * Create a module schedule item. The response contains the newly created module schedule item.
     * @returns ModuleScheduleItem Created
     * @throws ApiError
     */
    public createModuleScheduleByDomainId({
        contextId,
        requestBody,
        cache,
    }: {
        /**
         * will be used to set the contextID on the created module schedule item
         */
        contextId: number,
        requestBody: ModuleScheduleItemActionCreateActionCreate,
        cache?: boolean,
    }): CancelablePromise<ModuleScheduleItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/module-schedule',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update an existing module schedule item
     * Update an existing module schedule item. The response is an updated version of the updated module schedule item
     * @returns ModuleScheduleItem Success
     * @throws ApiError
     */
    public patchModuleScheduleByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        /**
         * will be used to update a specific module schedule item
         */
        contextId: number,
        /**
         * will be used to update a specific module schedule item
         */
        id: number,
        requestBody: ModuleScheduleItemActionPatch,
    }): CancelablePromise<ModuleScheduleItem> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/module-schedule/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an existing module schedule item
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteModuleScheduleByDomainId({
        contextId,
        id,
    }: {
        /**
         * will be used to remove a specific module schedule item
         */
        contextId: number,
        /**
         * will be used to remove a specific module schedule item
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/module-schedule/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
        });
    }
    /**
     * Get module schedule items
     * Return a list of module schedule items for a given domainID or platform and locale
     * @returns any Success
     * @throws ApiError
     */
    public listModuleScheduleByPlatformLocale({
        platform,
        locale,
        section,
        tagId,
        orderBy,
        order,
        scope,
        status,
        page = 1,
        perPage = 50,
        cache,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        /**
         * will return items for a specific locale
         */
        locale: LocaleID,
        /**
         * filter specific on the module schedule items for a given module
         */
        section?: ModuleScheduleSection,
        /**
         * filter on module schedule items with a given tagID (module schedule items's with NULL as tagID will also be returned), if null for scope public we only return items with tagID === null
         */
        tagId?: number,
        /**
         * will return items ordered by a given column
         */
        orderBy?: ModuleScheduleOrderByOptions,
        /**
         * order results in either ascending or descending order
         */
        order?: Order,
        scope?: Scope,
        /**
         * Filter items by ModuleScheduleStatus
         */
        status?: Array<ModuleScheduleStatus>,
        page?: number,
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<ModuleScheduleItem>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/locale/{locale}/module-schedule',
            path: {
                'platform': platform,
                'locale': locale,
            },
            query: {
                'section': section,
                'tagID': tagId,
                'orderBy': orderBy,
                'order': order,
                'scope': scope,
                'status': status,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create a module schedule item
     * Create a module schedule item. The response contains the newly created module schedule item.
     * @returns ModuleScheduleItem Created
     * @throws ApiError
     */
    public createModuleScheduleByPlatformLocale({
        platform,
        locale,
        requestBody,
        cache,
    }: {
        platform: PlatformID,
        locale: LocaleID,
        requestBody: ModuleScheduleItemActionCreateActionCreate,
        cache?: boolean,
    }): CancelablePromise<ModuleScheduleItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/platform/{platform}/locale/{locale}/module-schedule',
            path: {
                'platform': platform,
                'locale': locale,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update an existing module schedule item
     * Update an existing module schedule item. The response is an updated version of the updated module schedule item
     * @returns ModuleScheduleItem Success
     * @throws ApiError
     */
    public patchModuleScheduleByPlatformLocale({
        platform,
        locale,
        id,
        requestBody,
    }: {
        platform: PlatformID,
        locale: LocaleID,
        /**
         * will be used to update a specific module schedule item
         */
        id: number,
        requestBody: ModuleScheduleItemActionPatch,
    }): CancelablePromise<ModuleScheduleItem> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/locale/{locale}/module-schedule/{id}',
            path: {
                'platform': platform,
                'locale': locale,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an existing module schedule item
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteModuleScheduleByPlatformLocale({
        platform,
        locale,
        id,
    }: {
        platform: PlatformID,
        locale: LocaleID,
        /**
         * will be used to remove a specific module schedule item
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/platform/{platform}/locale/{locale}/module-schedule/{id}',
            path: {
                'platform': platform,
                'locale': locale,
                'id': id,
            },
        });
    }
}
