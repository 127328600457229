/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VideofeedConfig } from '../models/VideofeedConfig';
import type { VideofeedConfigClient } from '../models/VideofeedConfigClient';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VideofeedConfigService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get videofeed config by contextID
     * Return a videofeed config for a given contextID
     * @returns VideofeedConfig Success
     * @throws ApiError
     */
    public getVideofeedConfigByDomainId({
        contextId,
        client,
        cache,
    }: {
        contextId: number,
        client?: VideofeedConfigClient,
        cache?: boolean,
    }): CancelablePromise<VideofeedConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/videofeed-config',
            path: {
                'contextID': contextId,
            },
            query: {
                'client': client,
                'cache': cache,
            },
        });
    }
    /**
     * Create or update videofeed config by contextID
     * Creates/updates and returns a videofeed config for a given contextID
     * @returns VideofeedConfig Success
     * @throws ApiError
     */
    public upsertVideofeedConfigByDomainId({
        contextId,
        requestBody,
        client,
    }: {
        contextId: number,
        requestBody: VideofeedConfig,
        client?: VideofeedConfigClient,
    }): CancelablePromise<VideofeedConfig> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/domain/{contextID}/videofeed-config',
            path: {
                'contextID': contextId,
            },
            query: {
                'client': client,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
