import { FC, useCallback, useEffect, useState } from 'react';
import { find } from 'lodash';
import { NextRouter, useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData/useContextData';
import { QueryUtil } from '@common/utils/QueryUtil';
import { cleanLeadingSlash } from '@common/utils/UrlUtil';
import { fetchUnreadCountNotifications } from '@web/handlers/fetchNotifications';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { Navigation } from '@web/organisms/Header/molecules/Navigation';
import { SubNavigation } from '@web/organisms/Header/molecules/SubNavigation';
import { formatInternalRoute, Route } from '@web/routing';

import styles from './Header.module.scss';

interface MenuState {
    menuView: 'mobile' | 'desktop' | '';
    currentSearch: string;
    menuOpen: boolean;
    expandedSearch: 'top' | 'bottom' | '';
    notificationsCount: number;
    isLoggedIn: boolean;
}

const getCurrentSearch = (router: NextRouter) => {
    if (router.route === `/${Route.Search}`) {
        return QueryUtil.getQueryParam(router.query, 'q') || '';
    }
    return '';
};

export const Header: FC = () => {
    const router = useRouter();
    const contextData = useContextData();
    const __url = useTranslation('url').t;
    const [menuState, setMenuState] = useState<MenuState>({
        menuView: '',
        currentSearch: getCurrentSearch(router),
        menuOpen: false,
        expandedSearch: '',
        notificationsCount: 0,
        isLoggedIn: !!contextData.user?.id, //TODO: use supertokens status here.
    });

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAndUpdateNotifications = async () => {
            if (isLoading) {
                const _notificationsCount: number =
                    (await fetchUnreadCountNotifications(contextData, true))?.count || 0;

                setMenuState((prevState) => ({
                    ...prevState,
                    notificationsCount: _notificationsCount,
                }));
                setLoading(false);
            }
        };
        fetchAndUpdateNotifications();
    }, [contextData, isLoading]);

    const isBreakpointLarge = useResponsive({
        direction: MediaQueryDirection.above,
        size: MediaQuery.l,
    });

    useEffect(() => {
        const setMenuView = (view: MenuState['menuView']) => {
            setMenuState((prevState: MenuState) => ({
                ...prevState,
                menuView: view,
            }));
        };

        return isBreakpointLarge ? setMenuView('desktop') : setMenuView('mobile');
    }, [isBreakpointLarge]);

    const toggleMenu = useCallback(() => {
        setMenuState((prevState: MenuState) => ({
            ...prevState,
            menuOpen: !prevState.menuOpen,
            expandedSearch: '',
        }));
    }, []);

    const submitSearch = useCallback(
        (searchText: string) => {
            const formattedRoute = formatInternalRoute(Route.Search, contextData.context.slug, __url);
            router.push({ pathname: formattedRoute, query: { q: encodeURIComponent(searchText) } });
        },
        [__url, contextData.context.slug, router],
    );

    const handleLogin = useCallback(() => {
        const formattedRoute = formatInternalRoute(Route.Login, contextData.context.slug, __url);
        router.push(formattedRoute);
    }, [__url, contextData.context.slug, router]);

    const { menuView, menuOpen, currentSearch, isLoggedIn, notificationsCount } = menuState;

    const classes = [styles.Header];
    const isInverted = ![PlatformID.BR, PlatformID.VI].includes(contextData.platform.id);

    const currentUrl = cleanLeadingSlash(router.asPath || '');
    const mainNavItems = contextData.navItems?.filter((item) => item.isMain) || [];
    const currentNavItem = find(mainNavItems, (navItem) => navItem.url.includes(currentUrl));

    // VI's main items are shown in the subnav bar
    const subNavItems =
        contextData.platform.id === PlatformID.VI
            ? mainNavItems
            : currentNavItem?.children || mainNavItems?.[0]?.children;
    const modulesBySection = contextData.modulesBySection;
    const isNationalTheme = modulesBySection?.[ModuleScheduleSection.NATIONAL_THEME]?.[0];

    if (contextData.route === Route.Signin) {
        return null;
    }

    return (
        <header id="head" className={classes.join(' ')}>
            {/* TOP  MENU */}
            <section className={`${styles.top} ${isNationalTheme ? styles['national-theme'] : ''}`}>
                <div className={`full-width ${styles['top-container']}`}>
                    <Navigation
                        items={contextData.platform.id === PlatformID.VI ? [] : mainNavItems}
                        currentNavItem={currentNavItem}
                        isInverted={isInverted}
                        toggleMenu={toggleMenu}
                        currentSearch={currentSearch}
                        onSubmitSearch={submitSearch}
                        notificationsCount={notificationsCount}
                        isLoggedIn={isLoggedIn}
                        handleLogin={handleLogin}
                        menuView={menuView}
                        menuOpen={menuOpen}
                    />
                </div>
            </section>
            {/* SUB MENU */}
            {!menuOpen ? (
                <PlatformToggle include={[PlatformID.GP, PlatformID.BR, PlatformID.VI]}>
                    <section className={styles.bottom}>
                        <div className="full-width">
                            <SubNavigation
                                items={subNavItems}
                                currentSearch={currentSearch}
                                onSubmitSearch={submitSearch}
                                currentNavItem={currentNavItem}
                                menuView={menuView}
                            />
                        </div>
                    </section>
                </PlatformToggle>
            ) : null}
        </header>
    );
};
