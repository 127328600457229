import { Translate } from 'next-translate';

import { News, Platform, PlatformID } from '@common/clients/api';
import { DisplayType } from '@web/molecules/NewsList';

export const getDisplayTypeByPlatform = (platformId: PlatformID) => {
    switch (platformId) {
        case PlatformID.VP:
        case PlatformID.VN:
            return DisplayType.compact;
        default:
            return DisplayType.list;
    }
};

export const getDisplayTypeSwitchOptionsByPlatform = (platformId: PlatformID) => {
    switch (platformId) {
        default:
            return [DisplayType.compact, DisplayType.list];
    }
};

export const getMoreNewsButtonText = (items: News[] = [], platform: Platform, __translate: Translate) => {
    const hasItems = items && items.length > 0;
    if (!hasItems) {
        return '';
    }

    const moreVideosButtonText = __translate('more-videos');
    const moreArticlesButtonText =
        platform.id === PlatformID.GP || platform.id === PlatformID.BR
            ? __translate('more-news')
            : __translate('more-articles');

    const isMediaNewsList = items.every((item) => item?.category?.isMedia);
    return isMediaNewsList ? moreVideosButtonText : moreArticlesButtonText;
};
