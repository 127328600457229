/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SportDataCompetition } from '../models/SportDataCompetition';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CompetitionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get competition
     * Get competition
     * @returns SportDataCompetition Success
     * @throws ApiError
     */
    public getCompetitionOptaCompetitionByDomainId({
        contextId,
        id,
    }: {
        contextId: number,
        /**
         * OPTA ID of the competition or OPTA ID of the one of the tournaments from the competition.
         */
        id: string,
    }): CancelablePromise<SportDataCompetition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/opta/competition/by-tag/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
        });
    }
}
