import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/atoms/Icon';
import { Logo } from '@common/atoms/Logo';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import styles from './BettingDisclaimer.module.scss';

export type Color = 'light' | 'dark';

interface Props {
    showEighteenPlusLogo?: boolean;
    color?: Color;
}

const Settings = () => {
    const __meta = useTranslation('meta').t;
    return (
        <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
            <a className={styles.settings} rel="nofollow" target="_blank">
                <Icon.settings />
                <div className={`${styles['settings-text']} settings-text`}>{__meta`label.hide-betting`}</div>
            </a>
        </InternalLink>
    );
};

const BettingDisclaimerInformation = (props: { showEighteenPlusLogo?: boolean }) => {
    const __meta = useTranslation('meta').t;
    if (props.showEighteenPlusLogo) {
        return (
            <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
                <a rel="nofollow" target="_blank">
                    <span className={styles['eighteen-plus-with-logo']}>
                        <Logo.eighteenPlus />
                    </span>
                </a>
            </InternalLink>
        );
    }

    return (
        <InternalLink route={Route.Page} query={{ slug: __meta('url:play-responsibly') }}>
            <a rel="nofollow" target="_blank">
                <div className={styles['eighteen-plus-no-logo']}>
                    <div>{__meta('disclaimer:what-does-gambling-cost-you')}</div>
                    <div>{__meta('disclaimer:stop-in-time-18-plus')}</div>
                </div>
            </a>
        </InternalLink>
    );
};

export const BettingDisclaimer: FC<Props> = ({ showEighteenPlusLogo, color = 'dark' }) => {
    return (
        <div className={`${styles.Disclaimer} ${styles[color]}`}>
            <Settings />
            <BettingDisclaimerInformation showEighteenPlusLogo={showEighteenPlusLogo} />
        </div>
    );
};
