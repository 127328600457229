import {
    AlternateLink,
    ExperimentGroupOrRobots,
    FullContext,
    NavItem,
    Platform,
    Tag,
    User,
} from '@common/clients/api';
import { SupertokensStatus } from '@common/supertokens/SupertokensStatus';
import { AdsByPlacement } from '@common/types';
import { ResolvedPromiseHolder } from '@common/utils/PromiseHolder';
import { ModulesBySection } from '@web/handlers/fetchActiveModules';
import { SidebarData } from '@web/organisms/Sidebar/types';
import { Route } from '@web/routing';

import { ApiProps } from './ApiProps';
import { CaptchaProps } from './CaptchaProps';
import { CrossPlatformByPlatformId } from './CrossPlatformByPlatformId';

export interface CachedContextData {
    context?: FullContext;
    contexts: FullContext[];
    env: Record<string, string>;
    hostname: string;
    platform: Platform;
    config: {
        api: ApiProps;
        socket: ApiProps;
        sportmonksF1: ApiProps;
        crossPlatform: CrossPlatformByPlatformId;
        captchaConfig: CaptchaProps;
    };
}

export interface ContextData extends CachedContextData {
    ads?: AdsByPlacement | Promise<AdsByPlacement | undefined>;
    adsEnabled: boolean;
    allowBetting: boolean | Promise<boolean>;
    allowTracking: boolean;
    alternateLinks?: AlternateLink[] | Promise<AlternateLink[]>;
    context: FullContext;
    experimentGroup?: ExperimentGroupOrRobots;
    isDarkWebCall?: boolean; // TODO: remove this once php code is gone
    isPlatformContext: boolean;
    magnoliaSessionID: string;
    modulesBySection?: ModulesBySection | Promise<ModulesBySection | undefined>;
    route?: Route;
    path?: string;
    user?: User | Promise<User | undefined>;
    userAgent: string;
    userID: number;
    userSessionID: string;
    sidebarProps?: SidebarData | Promise<SidebarData | undefined>;
    navItems?: NavItem[] | Promise<NavItem[]>;
    trendingTags?: Tag[] | Promise<Tag[]>;
    supertokens: {
        status: SupertokensStatus;
        // Properties below correspond to SuperTokens cookies.
        // See https://supertokens.com/docs/thirdparty/common-customizations/sessions/cookie-consent
        sAccessToken: string;
        sRefreshToken: string;
        sFrontToken: string;
        sAntiCsrf: string;
        'st-last-access-token-update': string;
    };
}

export type ResolvedContextData = ResolvedPromiseHolder<ContextData>;

export const isContextData = (x: ContextData | any): x is ContextData => !!x?.config?.api?.host;
