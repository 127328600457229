import { FC, ReactElement } from 'react';

import { Badge, BadgeBg, BadgePosition, BadgeSize } from '@web/atoms/Badge';

import styles from './IconWithCounter.module.scss';

interface IconWithCounterProps {
    icon: ReactElement;
    badgeText?: number | string;
    badgeBg?: BadgeBg;
    badgeSize?: BadgeSize;
    badgePosition?: BadgePosition;
}

export const IconWithCounter: FC<IconWithCounterProps> = ({
    icon,
    badgeText,
    badgeBg = 'fourth',
    badgeSize = 'small',
    badgePosition = 'top-right',
}) => {
    return (
        <span className={styles.IconWithCounter}>
            {icon}
            {!!badgeText ? (
                <Badge text={badgeText} bg={badgeBg} position={badgePosition} size={badgeSize} />
            ) : null}
        </span>
    );
};
