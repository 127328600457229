import { FC } from 'react';
import Head from 'next/head';

import { InternalLink, Route } from '@web/atoms/InternalLink';
import { NewsList } from '@web/molecules/NewsList';

import { MainContent } from './atoms';
import { ErrorIntroBlock, ErrorText } from './molecules';
import { Props } from './types';

export const PageNotFoundView: FC<Props<404>> = ({ contextData, state, errorCode }) => {
    const platform = contextData?.platform;
    const news = state?.news;

    const { buttonLabel, description, newsListTitle, statusCodeAlias, subtitle } = state?.translations || {};
    const title = `${errorCode} - ${statusCodeAlias} | ${platform?.name || ''}`;

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="robots" content="noindex" />
            </Head>
            <MainContent>
                <ErrorIntroBlock platform={platform} tagText={statusCodeAlias}>
                    <ErrorText
                        platform={platform}
                        content={{
                            errorCode: errorCode!,
                            description,
                            subtitle,
                        }}
                    />
                    <InternalLink route={Route.Homepage} className="button error-button">
                        {buttonLabel}
                    </InternalLink>
                </ErrorIntroBlock>
                {news && news.length ? <NewsList title={<h3>{newsListTitle}</h3>} items={news} /> : null}
            </MainContent>
        </>
    );
};
