/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Author } from '../models/Author';
import type { EmailOrUsernameExists } from '../models/EmailOrUsernameExists';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';
import type { ProfileActionPatch } from '../models/ProfileActionPatch';
import type { User } from '../models/User';
import type { UserActionPatch } from '../models/UserActionPatch';
import type { UserRole } from '../models/UserRole';
import type { UserSessionDeleteResult } from '../models/UserSessionDeleteResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a list of ignored userIDs.
     * Return a list of ignored userIDs, filtered by the authenticated user.
     * @returns number Success
     * @throws ApiError
     */
    public listIgnoredUser({
        cache,
    }: {
        cache?: boolean,
    }): CancelablePromise<Array<number>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/ignore',
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Ignore multiple users by userIDs.
     * Add multiple userIDs to the list of ignored users.
     * @returns number Created
     * @throws ApiError
     */
    public addIgnoredUser({
        requestBody,
    }: {
        requestBody: Array<number>,
    }): CancelablePromise<Array<number>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/ignore',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an ignored user by userID
     * Delete a userID from the list of ignored users.
     * @returns void
     * @throws ApiError
     */
    public deleteIgnoredUser({
        userId,
    }: {
        /**
         * userID of the user that should be removed from the current user's ignored user list
         */
        userId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/ignore/{userID}',
            path: {
                'userID': userId,
            },
        });
    }
    /**
     * Get user list
     * Get a list of all users
     * @returns any Success
     * @throws ApiError
     */
    public listUser({
        search,
        page = 1,
        perPage = 20,
        userRoles,
        cache,
    }: {
        search?: string,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        /**
         * filter on userRole
         */
        userRoles?: Array<UserRole>,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<User>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            query: {
                'search': search,
                'page': page,
                'perPage': perPage,
                'userRoles': userRoles,
                'cache': cache,
            },
        });
    }
    /**
     * Get user-author by slug.
     * Get user-author by slug.
     * @returns Author Success
     * @throws ApiError
     */
    public getByCustomSlugUser({
        slug,
    }: {
        /**
         * Get user by customSlug
         */
        slug: string,
    }): CancelablePromise<Author> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/author/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
    /**
     * Get user profile for the current user, using Supertokens authentication
     * @returns User Success
     * @throws ApiError
     */
    public getProfileByIdUser(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/profile',
        });
    }
    /**
     * Update personal profile data
     * Update personal profile information
     * @returns User Success
     * @throws ApiError
     */
    public patchProfileUser({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: ProfileActionPatch,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/profile',
            query: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Get all tenants that the logged in user is connected to.
     * @returns any Success
     * @throws ApiError
     */
    public getUserTenantsUser(): CancelablePromise<Array<Record<string, any>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/tenant',
        });
    }
    /**
     * Associate user with a tenant
     * @returns void
     * @throws ApiError
     */
    public associateUserWithTenantUser({
        tenantId,
    }: {
        tenantId: PlatformID,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/tenant/{tenantID}/associate-user',
            path: {
                'tenantID': tenantId,
            },
        });
    }
    /**
     * Get user by id.
     * Get user by id.
     * @returns User Success
     * @throws ApiError
     */
    public getByIdUser({
        id,
    }: {
        /**
         * Get user by id
         */
        id: number,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Check if a username exists.
     * @returns boolean Success
     * @throws ApiError
     */
    public usernameExistsUser({
        username,
    }: {
        username: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/username/exists',
            query: {
                'username': username,
            },
        });
    }
    /**
     * Check if an email exists.
     * @returns boolean Success
     * @throws ApiError
     */
    public emailExistsUser({
        email,
    }: {
        email: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/email/exists',
            query: {
                'email': email,
            },
        });
    }
    /**
     * Check if email or username exists.
     * @returns EmailOrUsernameExists Success
     * @throws ApiError
     */
    public emailOrUsernameExistsUser({
        emailOrUsername,
    }: {
        emailOrUsername: string,
    }): CancelablePromise<EmailOrUsernameExists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/email-or-username/exists',
            query: {
                'emailOrUsername': emailOrUsername,
            },
        });
    }
    /**
     * Update entry
     * Update a specific user
     * @returns User Success
     * @throws ApiError
     */
    public patchUser({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody: UserActionPatch,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Delete a user
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteUser({
        userId,
    }: {
        /**
         * Will be used to remove a user
         */
        userId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
        });
    }
    /**
     * Delete all user sessions older than a certain time
     * Deletes all user sessions older than a certain time
     * @returns UserSessionDeleteResult Success
     * @throws ApiError
     */
    public cleanupUserSessionsUser({
        daysOld,
    }: {
        /**
         * Delete all user sessions older than a certain time
         */
        daysOld: number,
    }): CancelablePromise<UserSessionDeleteResult> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/sessions',
            query: {
                'daysOld': daysOld,
            },
        });
    }
}
