import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './Flexbox.module.scss';

type FlexboxProps = PropsWithChildren & {
    classes?: string;
    position: 'relative' | 'absolute';
    justify?: CSSProperties['justifyContent'];
    align?: CSSProperties['alignItems'];
    fillParent?: boolean;
};

type RowProps = FlexboxProps & { row: true; column?: never };
type ColumnProps = FlexboxProps & { column: true; row?: never };

type Props = RowProps | ColumnProps;

export const Flexbox: FC<Props> = ({
    children,
    classes: customClasses,
    position,
    justify,
    align,
    fillParent,
    ...props
}) => {
    const directionClass = 'row' in props ? styles.row : styles.column;
    const positionClass = position === 'relative' ? styles.relative : styles.absolute;

    const fillParentClass = fillParent
        ? position === 'absolute'
            ? styles['fill-parent-absolute']
            : styles['fill-parent']
        : '';

    const classes = `${styles.Flexbox} ${directionClass} ${positionClass} ${fillParentClass} ${customClasses}`;

    return (
        <div
            className={classes}
            style={{
                justifyContent: justify,
                alignItems: align,
            }}
        >
            {children}
        </div>
    );
};
