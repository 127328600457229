import { FC } from 'react';

import { Icon } from '@common/atoms/Icon';

import styles from './Time.module.scss';

interface Props {
    time: string;
}

export const Time: FC<Props> = ({ time }) => (
    <span className={styles.Time}>
        <Icon.play />
        {time}
    </span>
);
