/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FlaggedComment } from '../models/FlaggedComment';
import type { PlatformID } from '../models/PlatformID';
import type { Scope } from '../models/Scope';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FlaggedCommentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a list of flagged commentIDs for a user on a news article.
     * Return a list of flagged commentIDs, filtered by the authenticated user and a newsID.
     * @returns FlaggedComment Success
     * @throws ApiError
     */
    public listFlaggedCommentByDomainId({
        contextId,
        newsId,
        userId,
        scope,
        cache,
    }: {
        contextId: number,
        newsId: number,
        userId: number,
        scope?: Scope,
        cache?: boolean,
    }): CancelablePromise<Array<FlaggedComment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/comment/flag',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'newsID': newsId,
                'userID': userId,
                'cache': cache,
            },
        });
    }
    /**
     * Flag a comment by commentID
     * Flag a comment by commentID. Returns new flagged comment serialized by public scope.
     * @returns FlaggedComment Created
     * @throws ApiError
     */
    public createFlaggedCommentByDomainId({
        contextId,
        commentId,
    }: {
        contextId: number,
        commentId: number,
    }): CancelablePromise<FlaggedComment> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/comment/{commentID}/flag',
            path: {
                'contextID': contextId,
                'commentID': commentId,
            },
        });
    }
    /**
     * Delete a flagged comment by commentID
     * Delete a flagged comment by commentID.
     * @returns void
     * @throws ApiError
     */
    public deleteFlaggedCommentByDomainId({
        contextId,
        commentId,
    }: {
        contextId: number,
        commentId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/comment/{commentID}/flag',
            path: {
                'contextID': contextId,
                'commentID': commentId,
            },
        });
    }
    /**
     * Get a list of flagged commentIDs for a user on a news article.
     * Return a list of flagged commentIDs, filtered by the authenticated user and a newsID.
     * @returns FlaggedComment Success
     * @throws ApiError
     */
    public listFlaggedCommentByPlatform({
        platform,
        newsId,
        userId,
        scope,
        cache,
    }: {
        platform: PlatformID,
        newsId: number,
        userId: number,
        scope?: Scope,
        cache?: boolean,
    }): CancelablePromise<Array<FlaggedComment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/comment/flag',
            path: {
                'platform': platform,
            },
            query: {
                'scope': scope,
                'newsID': newsId,
                'userID': userId,
                'cache': cache,
            },
        });
    }
    /**
     * Flag a comment by commentID
     * Flag a comment by commentID. Returns new flagged comment serialized by public scope.
     * @returns FlaggedComment Created
     * @throws ApiError
     */
    public createFlaggedCommentByPlatform({
        platform,
        commentId,
    }: {
        platform: PlatformID,
        commentId: number,
    }): CancelablePromise<FlaggedComment> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/platform/{platform}/comment/{commentID}/flag',
            path: {
                'platform': platform,
                'commentID': commentId,
            },
        });
    }
    /**
     * Delete a flagged comment by commentID
     * Delete a flagged comment by commentID.
     * @returns void
     * @throws ApiError
     */
    public deleteFlaggedCommentByPlatform({
        platform,
        commentId,
    }: {
        platform: PlatformID,
        commentId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/platform/{platform}/comment/{commentID}/flag',
            path: {
                'platform': platform,
                'commentID': commentId,
            },
        });
    }
}
