import { FC } from 'react';

import { PlatformID } from '@common/clients/api';
import { Flexbox } from '@web/atoms/Flexbox';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import styles from './ReadMoreButton.module.scss';

interface Props {
    icon: JSX.Element;
    isHighlighted?: boolean;
    label: string;
    onClick: () => void;
}

export const ReadMoreButton: FC<Props> = ({ onClick, isHighlighted, label, icon }) => {
    const classes = `${styles.ReadMoreButton} ${isHighlighted ? styles['highlighted'] : ''}`;
    const hasIcon = icon !== undefined;
    const innerContent = hasIcon ? (
        <>
            {label} {icon}
        </>
    ) : (
        label
    );
    return (
        <>
            <PlatformToggle include={PlatformID.VI}>
                <Flexbox
                    row
                    classes={styles['line-behind-button']}
                    position="relative"
                    justify="center"
                    align="center"
                >
                    <>
                        <hr />
                        <button className={classes} onClick={onClick}>
                            {innerContent}
                        </button>
                    </>
                </Flexbox>
            </PlatformToggle>
            <PlatformToggle exclude={PlatformID.VI}>
                <button className={classes} onClick={onClick}>
                    {innerContent}
                </button>
            </PlatformToggle>
        </>
    );
};
