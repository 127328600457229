import { FC, useState } from 'react';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';

import { WebpWidth, webpWidths } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';

import { StaticImage } from '../StaticImage';

export interface Props extends Partial<ImageProps> {
    src?: string;
    defaultSize: WebpWidth;
    ratio?: number;
    fallbackSrc?: string;
}

const imageLoader = (props: ImageLoaderProps) =>
    props.src.replace('{WebpWidth}', `${getRightSize(props.width)}`);

const getRightSize = (width: number): number => {
    const result = webpWidths.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
    });
    return result;
};

export const WebpImage: FC<Props> = ({ defaultSize, ratio, alt, src, fallbackSrc, ...props }) => {
    const { platform } = useContextData();
    const [useFallback, setUseFallback] = useState<boolean>(!src);
    const width = props.fill === true ? undefined : defaultSize;
    const height = width && ratio ? width / ratio : undefined;

    const stylingProps: Partial<ImageProps> = {
        width,
        height: props.fill === true ? undefined : height,
        style: {
            objectFit: 'cover',
            objectPosition: 'top center',
            aspectRatio: width && ratio ? `${ratio * 1000} / 1000` : undefined,
        },
    };

    return !useFallback ? (
        <Image
            alt={alt || ''}
            loader={imageLoader}
            src={src || ''}
            onError={() => {
                setUseFallback(true);
            }}
            {...stylingProps}
            {...props}
        />
    ) : (
        <StaticImage // The fallback image per platform
            alt={`fallback image ${alt}`}
            src={fallbackSrc || `/images/${platform.id}/placeholder.jpg`}
            {...stylingProps}
            {...props}
        />
    );
};
