/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsMarketingBlock } from '../models/NewsMarketingBlock';
import type { Scope } from '../models/Scope';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NewsMarketingBlockService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get marketing block item by domain
     * Return marketing block item for a given context
     * @returns NewsMarketingBlock Success
     * @throws ApiError
     */
    public getNewsMarketingBlockByDomainId({
        contextId,
        scope,
    }: {
        contextId: number,
        scope?: Scope,
    }): CancelablePromise<NewsMarketingBlock> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news-marketing-block',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
            },
        });
    }
    /**
     * Create a new marketing news block
     * Creates and returns a new marketing news block for a gives context
     * @returns NewsMarketingBlock Created
     * @throws ApiError
     */
    public createNewsMarketingBlockByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: NewsMarketingBlock,
    }): CancelablePromise<NewsMarketingBlock> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/news-marketing-block',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update an existing marketing news block item
     * Updates and returns the edited marketing news block item
     * @returns NewsMarketingBlock Success
     * @throws ApiError
     */
    public patchNewsMarketingBlockByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: NewsMarketingBlock,
    }): CancelablePromise<NewsMarketingBlock> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/news-marketing-block',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an existing marketing news block for a given context
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteNewsMarketingBlockByDomainId({
        contextId,
    }: {
        contextId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/news-marketing-block',
            path: {
                'contextID': contextId,
            },
        });
    }
}
