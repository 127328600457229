const i18nUtils = require('../i18n/src/utils/index.js');

const defaultLocale = process.env.DEFAULT_LOCALE || 'en';
const locales = (process.env.LOCALES || 'en').replace(/_/g, '-').split(',');

module.exports = {
    defaultLocale: defaultLocale,
    loadLocaleFrom: i18nUtils.loadLocaleFrom,
    locales: locales,
    logger: i18nUtils.missingKeyLogger,
    loggerEnvironment: 'both',
    logBuild: false,
    pages: {
        '*': [
            'actions',
            'betting',
            'common',
            'components',
            'datetime',
            'error',
            'footer',
            'meta',
            'newslist',
            'sidebar',
            'stats',
            'url',
        ],
        '/sandbox': ['sandbox'],
        '/': ['videos'],
        'rgx:/livescores': ['livescores'],
        'rgx:/competitions': ['livescores', 'competitions'],
        'rgx:/clubs': ['livescores'],
        'rgx:/news': ['articles', 'comments', 'post-comment', 'livescores', 'videos'],
        'rgx:/videos': ['articles', 'comments', 'post-comment', 'videos'],
        'rgx:/profile': ['profile'],
        'rgx:/user': ['user', 'author'],
        'rgx:/search': ['search'],
        'rgx:/secure': ['secure'],
    },
};
