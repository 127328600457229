import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { InternalLink, Route } from '@web/atoms/InternalLink';
import { MainContent } from '@web/templates/ErrorPage/atoms';

import { Props } from './types';

export const DefaultErrorView: FC<Props> = (props) => {
    const __error = useTranslation('error').t;

    return (
        <MainContent>
            <article className="error inverted">
                <h3>{__error`subtitle`}</h3>
                <h1>{props.errorCode}</h1>
                <p>{__error`description`}</p>
                <InternalLink route={Route.Homepage} className="button error-button">
                    {__error('button')}
                </InternalLink>
            </article>
        </MainContent>
    );
};
