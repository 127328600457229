import { Translate } from 'next-translate';

import { LocaleID } from '@common/clients/api';
import { getIsoForLocale } from '@common/utils/LocaleUtil';

import { DateTimeDuration } from './DateTimeDuration';

// TODO: add more types
export enum Format {
    /** @example "maandag 1 januari" */
    DATE_DAY_MONTH,
    /** @example "maandag 1 januari 2000" */
    DATE_FULL,
    /** @example "di 1 januari 2000" */
    DATE_LONG,
    /** @example "di 1 januari" for this year and "di 1 januari 2000" when it's not the current year */
    DATE_LONG_OPTIONAL_YEAR,
    /** @example "di 1 januari" */
    DATE_LONG_WITHOUT_YEAR,
    /** @example "di 1.1.21" */
    DATE_DAY_NUMERIC,
    /** @example "dinsdag 1.1.21" */
    DATE_DAY_NUMERIC_LONG,
    /** @example " 1 januari 2000" */
    DATE_MEDIUM,
    /** @example "1 januari" for this year and "1 januari 2019" when it's not the current year */
    DATE_MEDIUM_OPTIONAL_YEAR,
    /** @example "1 januari" */
    DATE_MEDIUM_WITHOUT_YEAR,
    /** @example "01-01-2000" */
    DATE_NUMERIC,
    /** @example "01-01" */
    DATE_NUMERIC_SHORT,
    /** @example "2000-01-01" */
    DATE_PROGRAMMATIC,
    /** @example "2021-09-21T10:14:49.353Z " */
    DATE_TIME_ISO,
    /** @example "di 1 januari 2000 01:01 " */
    DATE_TIME_LONG,
    /** @example "01-01-2000 01:01" */
    DATE_TIME_NUMERIC,
    /** @example "2000-01-01 01:01:01 " */
    DATE_TIME_PROGRAMMATIC_SPACED,
    /** @example "2000-01-01T01:01:01 " */
    DATE_TIME_PROGRAMMATIC,
    /** @example "Tue, 01 Jan 2000 00:00:00 +0000 " */
    DATE_TIME_RFC_2822,
    /** @example "dinsdag " */
    DAY_LONG,
    /** @example "di " */
    DAY_SHORT,
    /** @example "januari 2000 " */
    MONTH_FULL,
    /** @example "januari " */
    MONTH_LONG,
    /** @example "jan " */
    MONTH_SHORT,
    /** @example "01:01" */
    TIME_NUMERIC,
}

export enum DurationFormat {
    DURATION_MEDIUM, // 11 hours and 12 minutes
    DURATION_SHORT, // 11h5m
}

export enum Perspective {
    NONE,
    IN_AGO,
    STILL_AGO,
}

type FormattersList = {
    [key in Format]?: Intl.DateTimeFormat;
};

type FormattersListByLocale = {
    [key in LocaleID]?: FormattersList;
};

interface formatDurationProps {
    date: string | Date;
    __translate: Translate;
    perspective?: Perspective | null;
    includeSeconds?: boolean | null;
    includeMinutes?: boolean | null;
    includeHours?: boolean | null;
    format?: DurationFormat;
    /**
     * Maximum time units displayed for a timestamp.
     * Given this timestamp "1 year 3 months, 22 days, and 2 hours ago."
     * If set to 1, shows "1 year ago".
     * And if set to 2, shows "1 year and 3 months ago".
     * Default value = 7 (years, months, weeks, days, hours, minutes, seconds)
     */
    numOfPartsToShow?: number;
}

const convertToDoubleDigit = (number: number) => ('0' + number).slice(-2);

export const formatNumericDuration = (seconds: number): string => {
    if (!seconds) {
        return '';
    }
    const dateInterval: DateTimeDuration = new DateTimeDuration(new Date(Date.now() + seconds * 1000));
    return `${convertToDoubleDigit(dateInterval.minutes)}:${convertToDoubleDigit(dateInterval.seconds)}`;
};

export const formatDuration = ({
    date,
    __translate,
    perspective = null,
    includeSeconds = null,
    includeMinutes = null,
    includeHours = null,
    format = DurationFormat.DURATION_MEDIUM,
    numOfPartsToShow = 7,
}: formatDurationProps): string => {
    if (typeof date === 'string') {
        date = new Date(date);
    }

    const dateInterval: DateTimeDuration = new DateTimeDuration(date);

    const includeWeeks: boolean =
        (dateInterval.totalDays >= 7 && dateInterval.totalDays < 30) ||
        (!dateInterval.years && !dateInterval.months && dateInterval.days >= 7 && dateInterval.days < 30);
    includeHours = typeof includeHours === 'boolean' ? includeHours : dateInterval.totalDays <= 2;
    includeMinutes =
        typeof includeMinutes === 'boolean'
            ? includeMinutes
            : includeHours && !dateInterval.totalDays && dateInterval.hours < 5;
    includeSeconds =
        typeof includeSeconds === 'boolean' ? includeSeconds : includeMinutes && dateInterval.minutes < 5;

    const addTranslatedPart = (translationKey: string, value: number) => {
        if (value === 1) {
            items.push(__translate(`datetime:${formatPrefix}${translationKey}`));
        } else {
            items.push(__translate(`datetime:${formatPrefix}${translationKey}s`, { num: value }));
        }
        numOfPartsToShow--;
    };

    const items: string[] = [];

    const formatPrefix = format === DurationFormat.DURATION_SHORT ? 'short.' : '';

    if (dateInterval.years && numOfPartsToShow > 0) {
        addTranslatedPart('year', dateInterval.years);
    }

    if (dateInterval.months && numOfPartsToShow > 0) {
        addTranslatedPart('month', dateInterval.months);
    }

    if (includeWeeks && numOfPartsToShow > 0) {
        let weeks = 0;
        while (dateInterval.days >= 7) {
            dateInterval.days -= 7;
            weeks++;
        }
        if (weeks > 0) {
            addTranslatedPart('week', weeks);
        }
    }

    if (dateInterval.days && numOfPartsToShow > 0) {
        addTranslatedPart('day', dateInterval.days);
    }

    if (dateInterval.hours && includeHours && numOfPartsToShow > 0) {
        addTranslatedPart('hour', dateInterval.hours);
    }

    if (dateInterval.minutes && includeMinutes && numOfPartsToShow > 0) {
        addTranslatedPart('minute', dateInterval.minutes);
    }

    if (dateInterval.seconds && includeSeconds && numOfPartsToShow > 0) {
        addTranslatedPart('second', dateInterval.seconds);
    }

    let result = '';
    const length = items.length;
    if (length === 1) {
        result = items.toString();
    } else {
        items.map((item: string, index) => {
            if (format === DurationFormat.DURATION_SHORT) {
                result += item;
            } else if (index + 1 === length) {
                //last item
                result += __translate('datetime:separationEnd') + item;
            } else {
                result += item + __translate('datetime:separation');
            }
        });
    }

    let perspectiveKey = '';
    switch (perspective) {
        case Perspective.IN_AGO:
            perspectiveKey = dateInterval.inFuture ? 'in' : 'ago';
            break;
        case Perspective.STILL_AGO:
            perspectiveKey = dateInterval.inFuture ? 'still' : 'ago';
            break;
    }

    if (!result) return '-';

    return perspectiveKey
        ? __translate('datetime:perspective.' + perspectiveKey, { duration: result })
        : result;
};

export const getCleanDate = (date: Date | string | number): Date => {
    if (date instanceof Date) {
        return date;
    } else if (typeof date === 'number') {
        return new Date(date * 1000);
    }
    return new Date(date);
};

export class DateTimeUtil {
    private static formatters: FormattersListByLocale = {};

    public static format(
        _date: Date | string | number | undefined,
        format: Format = Format.DATE_TIME_NUMERIC,
        locale: LocaleID = LocaleID.NL_NL,
    ): string {
        if (!_date) return '';
        const date: Date = getCleanDate(_date);
        if (isNaN(date.getTime())) return '';

        switch (format) {
            case Format.DATE_TIME_ISO:
                return date.toISOString();
            case Format.DATE_PROGRAMMATIC:
                return this.getDateProgrammatic(date);
            case Format.DATE_TIME_PROGRAMMATIC:
                return this.getDateProgrammatic(date) + 'T' + this.getTimeProgrammatic(date);
            case Format.DATE_TIME_PROGRAMMATIC_SPACED:
                return this.getDateProgrammatic(date) + ' ' + this.getTimeProgrammatic(date);
            case Format.DATE_TIME_RFC_2822:
                return this.getDateTimeRFC(date);
            case Format.DATE_MEDIUM_OPTIONAL_YEAR:
                return this.format(
                    date,
                    new Date().getFullYear() === date.getFullYear()
                        ? Format.DATE_MEDIUM_WITHOUT_YEAR
                        : Format.DATE_MEDIUM,
                    locale,
                );
            case Format.DATE_LONG_OPTIONAL_YEAR:
                return this.format(
                    date,
                    new Date().getFullYear() === date.getFullYear()
                        ? Format.DATE_LONG_WITHOUT_YEAR
                        : Format.DATE_LONG,
                    locale,
                );
            default:
                // TODO: Find a way to not regenerate these over and over again;
                const formatter = this.getFormatter(format, locale);
                return formatter.format(date);
        }
    }

    private static getDateProgrammatic(date: Date): string {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    }

    private static getTimeProgrammatic(date: Date): string {
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return hours + ':' + minutes + ':' + seconds;
    }

    public static getDateTimeRFC(date: Date) {
        const utcString = date.toUTCString();
        const dateObj = getCleanDate(utcString);
        const offset = dateObj.getTimezoneOffset();

        dateObj.setUTCMinutes(dateObj.getUTCMinutes() - offset);

        const offsetHours = ('0' + Math.abs(Math.floor(offset / 60))).slice(-2);
        const offsetMinutes = ('0' + Math.abs(offset % 60)).slice(-2);
        const offsetSign = offset < 0 ? '+' : '-';
        const offsetString = offsetSign + offsetHours + offsetMinutes;

        return dateObj.toUTCString().replace('GMT', offsetString);
    }

    public static getTimeZone(locale: LocaleID): string {
        switch (locale) {
            case LocaleID.NL_NL:
                return 'Europe/Amsterdam';
            case LocaleID.DE:
                return 'Europe/Berlin';
            case LocaleID.EN:
                return 'Europe/London';
            case LocaleID.ES:
                return 'Europe/Madrid';
            case LocaleID.FR:
                return 'Europe/Paris';
            case LocaleID.IT:
                return 'Europe/Rome';
            case LocaleID.PT_BR:
                return 'America/Sao_Paulo';
            case LocaleID.PT_PT:
                return 'Europe/Lisbon';
        }

        return 'Europe/Amsterdam';
    }

    private static getFormatter(
        format: Format = Format.DATE_TIME_NUMERIC,
        locale: LocaleID = LocaleID.NL_NL,
    ): Intl.DateTimeFormat {
        if (this.formatters[locale] && this.formatters?.[locale]?.[format]) {
            // @ts-ignore: Object is possibly 'null'.
            return this.formatters[locale][format];
        }

        const localeIso = getIsoForLocale(locale);
        const config: Intl.DateTimeFormatOptions = {};
        config.timeZone = this.getTimeZone(locale);

        // Weekday format
        switch (format) {
            case Format.DAY_LONG:
            case Format.DATE_FULL:
            case Format.DATE_DAY_MONTH:
            case Format.DATE_DAY_NUMERIC_LONG:
                config.weekday = 'long';
                break;
            case Format.DAY_SHORT:
            case Format.DATE_LONG:
            case Format.DATE_LONG_WITHOUT_YEAR:
            case Format.DATE_DAY_NUMERIC:
            case Format.DATE_TIME_LONG:
                config.weekday = 'short';
                break;
        }

        // Date format
        switch (format) {
            case Format.TIME_NUMERIC:
            case Format.DAY_LONG:
            case Format.DAY_SHORT:
                config.day = undefined;
                config.month = undefined;
                config.year = undefined;
                break;

            case Format.DATE_FULL:
            case Format.DATE_LONG:
            case Format.DATE_MEDIUM:
            // @ts-expect-error - Fallthrough case in switch.
            case Format.DATE_TIME_LONG:
                config.day = 'numeric';
            case Format.MONTH_FULL:
                config.month = 'long';
                config.year = 'numeric';
                break;

            case Format.DATE_LONG_WITHOUT_YEAR:
                config.day = 'numeric';
                config.month = 'long';
                config.year = undefined;
                break;

            case Format.MONTH_SHORT:
                config.month = 'short';
                break;

            case Format.MONTH_LONG:
                config.month = 'long';
                break;

            case Format.DATE_DAY_MONTH:
            case Format.DATE_MEDIUM_WITHOUT_YEAR:
                config.day = 'numeric';
                config.month = 'long';
                config.year = undefined;
                break;

            case Format.DATE_NUMERIC:
            case Format.DATE_TIME_NUMERIC:
                config.day = '2-digit';
                config.month = '2-digit';
                config.year = 'numeric';
                break;

            case Format.DATE_NUMERIC_SHORT:
                config.day = '2-digit';
                config.month = '2-digit';
                config.year = undefined;
                break;

            case Format.DATE_DAY_NUMERIC:
            case Format.DATE_DAY_NUMERIC_LONG:
                config.day = '2-digit';
                config.month = '2-digit';
                config.year = '2-digit';
                break;
        }

        // Time Format
        config.second = undefined;
        switch (format) {
            case Format.DATE_NUMERIC:
            case Format.DATE_NUMERIC_SHORT:
                config.hour = undefined;
                config.minute = undefined;
                break;
            case Format.TIME_NUMERIC:
            case Format.DATE_TIME_NUMERIC:
            case Format.DATE_TIME_LONG:
                config.hour = '2-digit';
                config.minute = '2-digit';
                break;
        }

        if (!this.formatters[locale]) this.formatters[locale] = {};
        // @ts-ignore: Object is possibly 'null'.
        this.formatters[locale][format] = new Intl.DateTimeFormat(localeIso, config);
        // @ts-ignore: Object is possibly 'null'.
        return this.formatters[locale][format];
    }

    /** @deprecated you might want to import { getCleanDate } from '@common/utils/DateTimeUtil' directly instead of using DateTimeUtil.getCleanDate */
    public static getCleanDate = getCleanDate;

    /** @deprecated you might want to import { formatDuration } from '@common/utils/DateTimeUtil' directly instead of using DateTimeUtil.formatDuration */
    public static formatDuration = formatDuration;

    /** @deprecated you might want to import { Format } from '@common/utils/DateTimeUtil' directly instead of using DateTimeUtil.formats */
    public static formats = Format;

    /** @deprecated you might want to import { DurationFormat } from '@common/utils/DateTimeUtil' directly instead of using DateTimeUtil.DurationFormat */
    public static DurationFormat = DurationFormat;

    /** @deprecated you might want to import { Perspective } from '@common/utils/DateTimeUtil' directly instead of using DateTimeUtil.Perspective */
    public static Perspective = Perspective;
}
