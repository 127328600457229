/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdWrapperSize {
    H90 = 'h90',
    H250 = 'h250',
    H600 = 'h600',
    NULL = 'null',
}
