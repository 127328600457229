/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailVerify } from '../models/EmailVerify';
import type { PasswordReset } from '../models/PasswordReset';
import type { SignInPassword } from '../models/SignInPassword';
import type { SignUpPassword } from '../models/SignUpPassword';
import type { SupertokensAuthMode } from '../models/SupertokensAuthMode';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SecureService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Sign in using email+password or username+password.
     *
     * The `front-token` header contains the base64 encoded front token.
     *
     * If the request sets the header `st-auth-mode: cookie`, then the `sAccessToken` and `sRefreshToken` cookies will be set with the `Set-Cookie` header. If the request sets the header `st-auth-mode: header`, then the `st-access-token` header and `st-refresh-token` header will contain the access token and refresh token, respectively. For more information see <https://supertokens.com/docs/session/common-customizations/sessions/cookie-consent#information-about-our-session-cookies> and <https://supertokens.com/docs/emailpassword/common-customizations/sessions/token-transfer-method>.
     * @returns void
     * @throws ApiError
     */
    public signInPasswordSecure({
        stAuthMode,
        requestBody,
    }: {
        /**
         * When set to `use-header`, the access token and refresh token are returned with the `st-access-token` header and `st-refresh-token` header, respectively. When set to `use-cookie`, they are returned as cookies (using the `Set-Cookie` header). See <https://supertokens.com/docs/emailpassword/common-customizations/sessions/token-transfer-method>.
         */
        stAuthMode: SupertokensAuthMode,
        requestBody: SignInPassword,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/secure/sign-in-password',
            headers: {
                'st-auth-mode': stAuthMode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Sign up with email, username and password.
     *
     * The `front-token` header contains the base64 encoded front token.
     *
     * If the request sets the header `st-auth-mode: cookie`, then the `sAccessToken` and `sRefreshToken` cookies will be set with the `Set-Cookie` header. If the request sets the header `st-auth-mode: header`, then the `st-access-token` header and `st-refresh-token` header will contain the access token and refresh token, respectively. For more information see <https://supertokens.com/docs/session/common-customizations/sessions/cookie-consent#information-about-our-session-cookies> and <https://supertokens.com/docs/emailpassword/common-customizations/sessions/token-transfer-method>.
     * @returns void
     * @throws ApiError
     */
    public signUpPasswordSecure({
        stAuthMode,
        requestBody,
    }: {
        /**
         * When set to `use-header`, the access token and refresh token are returned with the `st-access-token` header and `st-refresh-token` header, respectively. When set to `use-cookie`, they are returned as cookies (using the `Set-Cookie` header). See <https://supertokens.com/docs/emailpassword/common-customizations/sessions/token-transfer-method>.
         */
        stAuthMode: SupertokensAuthMode,
        requestBody: SignUpPassword,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/secure/sign-up-password',
            headers: {
                'st-auth-mode': stAuthMode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Send a verification email to the user
     * @returns void
     * @throws ApiError
     */
    public sendVerificationEmailSecure(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/secure/send-verification-email',
        });
    }
    /**
     * Verify the email with the token the user received
     * @returns void
     * @throws ApiError
     */
    public verifyEmailSecure({
        requestBody,
    }: {
        requestBody: EmailVerify,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/secure/verify-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Send an email with a link to reset the user's password. A 204 code is returned even if the email does not exist to prevent enumeration attacks.
     * @returns void
     * @throws ApiError
     */
    public sendPasswordResetEmailSecure({
        requestBody,
    }: {
        requestBody: {
            email: string;
        },
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/secure/send-password-reset-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reset password
     * @returns void
     * @throws ApiError
     */
    public resetPasswordSecure({
        requestBody,
    }: {
        requestBody: PasswordReset,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/secure/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
}
