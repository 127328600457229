import { FC } from 'react';
import dynamic from 'next/dynamic';

import { PlatformID } from '@common/clients/api';

import styles from './PlatformLogo.module.scss';

const BbLogoMiniSvg = dynamic(() => import('@common/logos/bb-logo-mini.svg'));
const BrLogoMiniSvg = dynamic(() => import('@common/logos/br-logo-mini.svg'));
const BrLogoSvg = dynamic(() => import('@common/logos/br-dark.svg'));
const GpLogoMiniSvg = dynamic(() => import('@common/logos/gp-logo-mini.svg'));
const GpLogoSvg = dynamic(() => import('@common/logos/gp.svg'));
const ViLogoMiniSvg = dynamic(() => import('@common/logos/vi-logo-mini.svg'));
const VnLogoMiniSvg = dynamic(() => import('@common/logos/vn-logo-mini.svg'));
const VnLogoSvg = dynamic(() => import('@common/logos/vn.svg'));
const VpLogoSvg = dynamic(() => import('@common/logos/vp.svg'));
const ViLogoSvg = dynamic(() => import('@common/logos/vi.svg'));

export enum MissingPlatformID {
    BB = 'bb',
    SP = 'sp',
    VPBE = 'vpbe',
}

const getLogo = ({ platformID }: Props) => {
    switch (platformID) {
        case PlatformID.BR:
            return <BrLogoSvg />;
        case PlatformID.GP:
            return <GpLogoSvg />;
        case PlatformID.VN:
            return <VnLogoSvg />;
        case PlatformID.VP:
            return <VpLogoSvg />;
        case PlatformID.VI:
            return <ViLogoSvg />;
        default:
            return null;
    }
};

const getMiniLogo = ({ platformID }: Props) => {
    switch (platformID) {
        case PlatformID.BR:
            return <BrLogoMiniSvg />;
        case PlatformID.GP:
            return <GpLogoMiniSvg />;
        case PlatformID.VI:
            return <ViLogoMiniSvg />;
        case PlatformID.VN:
            return <VnLogoMiniSvg />;
        case PlatformID.VP:
            return <VpLogoSvg />;
        case MissingPlatformID.BB:
            return <BbLogoMiniSvg />;
        default:
            return null;
    }
};

interface Props {
    classes?: string;
    extraPadding?: boolean;
    isMini?: boolean;
    platformID: PlatformID | MissingPlatformID;
    hasBackground?: boolean;
}

export const PlatformLogo: FC<Props> = ({ classes, extraPadding, isMini, platformID, hasBackground }) => (
    <span
        className={`
        ${styles.PlatformLogo}
        ${isMini ? styles['is-mini'] : ''}
        ${extraPadding ? styles['extra-padding'] : ''}
        ${hasBackground ? styles['has-background'] : ''}
        ${classes || ''}`}
    >
        {isMini ? getMiniLogo({ platformID }) : getLogo({ platformID })}
    </span>
);
