import { FC } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { PlatformID, UserRole } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Avatar } from '@web/atoms';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { IconWithCounter } from '@web/molecules/IconWithCounter';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { LoginButton } from '@web/organisms/Header/atoms/LoginButton/LoginButton';

import styles from './UserArea.module.scss';

export interface Props {
    isLoggedIn?: boolean;
    adminURL: string;
    notificationsCount?: number;
    handleLogin: () => void;
}

export const UserArea: FC<Props> = ({ isLoggedIn, adminURL, notificationsCount, handleLogin }) => {
    const { user } = useContextData();

    return (
        <div className={styles.UserArea}>
            <PlatformToggle exclude={[PlatformID.BR]}>
                {isLoggedIn ? (
                    <>
                        {/* Add link to admin if user is an admin */}
                        {user?.role &&
                        [UserRole.MODERATOR, UserRole.EDITOR, UserRole.ADMIN].includes(user?.role) ? (
                            <Link href={adminURL} passHref={true}>
                                <Icon.admin />
                            </Link>
                        ) : null}
                        <InternalLink route={Route.Notifications}>
                            <IconWithCounter
                                icon={<Icon.bell />}
                                badgeText={notificationsCount}
                                badgeBg={'second'}
                            />
                        </InternalLink>
                        <InternalLink key="profile" route={Route.Profile}>
                            <Avatar src={user?.image} isRounded={true} isSmall />
                        </InternalLink>
                    </>
                ) : (
                    <LoginButton onClick={handleLogin} />
                )}
            </PlatformToggle>
        </div>
    );
};
