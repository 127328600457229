/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MatchTickerItem } from '../models/MatchTickerItem';
import type { MatchTickerItemActionCreate } from '../models/MatchTickerItemActionCreate';
import type { MatchTickerItemActionPatch } from '../models/MatchTickerItemActionPatch';
import type { MatchTickerOrderByOption } from '../models/MatchTickerOrderByOption';
import type { Pagination } from '../models/Pagination';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MatchTickerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List match ticker items
     * Returns a list of match ticker items selected by contextID.
     * @returns any Success
     * @throws ApiError
     */
    public listMatchTickerByDomainId({
        contextId,
        orderBy,
        page,
        perPage,
        cache,
    }: {
        /**
         * Selects items by given contextID.
         */
        contextId: number,
        /**
         * Orders items by given column name (ascending).
         */
        orderBy?: MatchTickerOrderByOption,
        /**
         * Selects items by page number.
         */
        page?: number,
        /**
         * Limits amount of items per page.
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<MatchTickerItem>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/match-ticker',
            path: {
                'contextID': contextId,
            },
            query: {
                'orderBy': orderBy,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }
    /**
     * Create match ticker item
     * Creates match ticker item for selected context.
     * @returns MatchTickerItem Created
     * @throws ApiError
     */
    public createMatchTickerByDomainId({
        contextId,
        requestBody,
    }: {
        /**
         * Links new match ticker item to selected context.
         */
        contextId: number,
        requestBody: MatchTickerItemActionCreate,
    }): CancelablePromise<MatchTickerItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/match-ticker',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deletes all match ticker items.
     * Deletes match ticker items by contextID. It returns 404 if no items were found.
     * @returns void
     * @throws ApiError
     */
    public deleteAllMatchTickerByDomainId({
        contextId,
    }: {
        /**
         * Selects match ticker item by contextID.
         */
        contextId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/match-ticker',
            path: {
                'contextID': contextId,
            },
        });
    }
    /**
     * Updates match ticker item
     * Updates match ticker item by contextID and matchID.
     * @returns MatchTickerItem Success
     * @throws ApiError
     */
    public patchMatchTickerByDomainId({
        contextId,
        matchId,
        requestBody,
    }: {
        /**
         * Selects match ticker item by contextID.
         */
        contextId: number,
        /**
         * Selects match ticker item by matchID.
         */
        matchId: string,
        requestBody: MatchTickerItemActionPatch,
    }): CancelablePromise<MatchTickerItem> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/match-ticker/{matchID}',
            path: {
                'contextID': contextId,
                'matchID': matchId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deletes match ticker item
     * Deletes match ticker item by contextID and matchID. It returns 404 if the match ticker item was not found.
     * @returns void
     * @throws ApiError
     */
    public deleteMatchTickerByDomainId({
        contextId,
        matchId,
    }: {
        /**
         * Selects match ticker item by contextID.
         */
        contextId: number,
        /**
         * Selects match ticker item by matchID.
         */
        matchId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/match-ticker/{matchID}',
            path: {
                'contextID': contextId,
                'matchID': matchId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
}
