/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Match } from '../models/Match';
import type { Order } from '../models/Order';
import type { Pagination } from '../models/Pagination';
import type { SportDataMatch } from '../models/SportDataMatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MatchService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get matches
     * @returns any Success
     * @throws ApiError
     */
    public listMatch({
        page,
        perPage,
        order,
        kickOffRangeStart,
        kickOffRangeEnd,
    }: {
        page?: number,
        perPage?: number,
        order?: Order,
        kickOffRangeStart?: string,
        kickOffRangeEnd?: string,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Match>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/match',
            query: {
                'page': page,
                'perPage': perPage,
                'order': order,
                'kickOffRangeStart': kickOffRangeStart,
                'kickOffRangeEnd': kickOffRangeEnd,
            },
        });
    }
    /**
     * Search matches close to the current date (returns max 8 results)
     * @returns any Success
     * @throws ApiError
     */
    public searchMatch({
        search,
    }: {
        search: string,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Match>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/match/search',
            query: {
                'search': search,
            },
        });
    }
    /**
     * Get basic info of a given match
     * Get basic info of a given match
     * @returns SportDataMatch Success
     * @throws ApiError
     */
    public getByIdOptaMatch({
        id,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * OPTA ID of the requested match
         */
        id: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<SportDataMatch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/match/{id}',
            path: {
                'id': id,
            },
            query: {
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get details of a given match
     * Get details of a given match
     * @returns SportDataMatch Success
     * @throws ApiError
     */
    public getMatchDetailsOptaMatch({
        id,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * OPTA ID of the requested match
         */
        id: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<SportDataMatch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/match/{id}/detail',
            path: {
                'id': id,
            },
            query: {
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get basic info of a given match
     * Get basic info of a given match
     * @returns SportDataMatch Success
     * @throws ApiError
     */
    public getBySlugOptaMatch({
        kickOffDate,
        slug,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Kick off date of the requested match. String in format yyyy-MM-dd.
         */
        kickOffDate: string,
        /**
         * Slug of the requested match
         */
        slug: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<SportDataMatch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/match/slug/{kickOffDate}/{slug}',
            path: {
                'kickOffDate': kickOffDate,
                'slug': slug,
            },
            query: {
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
    /**
     * Get details of a given match
     * Get details of a given match
     * @returns SportDataMatch Success
     * @throws ApiError
     */
    public getMatchDetailsBySlugOptaMatch({
        kickOffDate,
        slug,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Kick off date of the requested match. String in format yyyy-MM-dd.
         */
        kickOffDate: string,
        /**
         * Slug of the requested match
         */
        slug: string,
        /**
         * Bookmaker id
         */
        bookmakerId?: number,
        /**
         * Second bookmaker id
         */
        secondBookmakerId?: number,
    }): CancelablePromise<SportDataMatch> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/match/slug/{kickOffDate}/{slug}/detail',
            path: {
                'kickOffDate': kickOffDate,
                'slug': slug,
            },
            query: {
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
        });
    }
}
